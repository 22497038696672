import { Component, Input, OnInit } from '@angular/core';

/**
 * ID: bh-content-empty
 * Name: BH Empty
 * Description: Displays no-data (empty) messaging
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 */
@Component({
  selector: 'bh-content-empty',
  templateUrl: './bh-content-empty.component.html',
  styleUrls: ['./bh-content-empty.component.scss'],
})
export class BhContentEmptyComponent implements OnInit {
  @Input() ionIconName;
  @Input() messageHtml;

  constructor() { }

  ngOnInit() {}

}
