import { environment } from 'src/environments/environment';
import { Component, ContentChildren, ElementRef, Input, OnInit, QueryList } from '@angular/core';
import * as moment from 'moment';

/**
 * ID: bh-content-card
 * Name: BH Content Card
 * Description: A multi-functional data item that presents in full card, list item, compact hero card, and metric
 * Version: 3
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-06-23 - MW - v1: Initial dev
 * 2021-06-28 - MW - v2: Fixed subtitle date format
 * 2021-06-28 - MW - v3: Reduced strength of list item card-title font weight
 */
@Component({
  selector: 'bh-content-card',
  templateUrl: './bh-content-card.component.html',
  styleUrls: ['./bh-content-card.component.scss'],
})
export class BhContentCardComponent implements OnInit {
  env = environment;
  @Input() layout: 'full' | 'compact' | 'list' | 'metric' = 'full';
  @Input() title: string = null;
  @Input() subtitle: string = null;
  @Input() ionIcon: string = null;
  @Input() ionIconColor = 'primary';
  @Input() showIconBadge = false;
  @Input() views = 0;
  @Input() likes = 0;
  @Input() comments = 0;
  @Input() isFavorite = false;
  @Input() imageUrl: string = null;
  @Input() showSocialToolbar = false;
  @Input() maxWidth = 'none';
  @Input() metricValue;
  @Input() metricDetail;
  @ContentChildren('fullContent') fullContent: QueryList<ElementRef>;
  @ContentChildren('listContent') listContent: QueryList<ElementRef>;
  heroImageUrl = '';

  constructor() { }

  ngOnInit() {
    this.heroImageUrl = this.imageUrl ?
      (this.imageUrl.indexOf('local:') === 0) ? this.imageUrl.replace('local:', '/assets/').trim() :
        this.env.filesUrl + '/' + this.imageUrl.trim() : '';

    const subtitleMoment = moment(this.subtitle, 'M/D/YYYY HH:mm');
    if (subtitleMoment.isValid()) {
      this.subtitle = subtitleMoment.format('MMM D, YYYY');
    }
  }

}
