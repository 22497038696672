import { BhContentCardComponentModule } from './../../components/_core/bh-content-card/bh-content-card.component.module';
import { BhContentListItemComponentModule } from './../../components/_core/bh-content-list-item/bh-content-list-item.component.module';
import { BhContentEmptyComponentModule } from './../../components/_core/bh-content-empty/bh-content-empty.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { NotificationsPopoverPageRoutingModule } from './notifications-popover-routing.module';

import { NotificationsPopoverPage } from './notifications-popover.page';

@NgModule({
  imports: [
    BhContentEmptyComponentModule,
    BhContentCardComponentModule,
    BhContentListItemComponentModule,
    CommonModule,
    FormsModule,
    IonicModule,
    NotificationsPopoverPageRoutingModule
  ],
  declarations: [NotificationsPopoverPage]
})
export class NotificationsPopoverPageModule {}
