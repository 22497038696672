import { CaseReportView } from './../../models/case-report-view';
import { HelperUtilitiesService } from 'src/app/services/_core/helper-utilities/helper-utilities.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { ActivityService } from './../../services/activity/activity.service';
import { CategoryService } from 'src/app/services/category/category.service';
import { AttendingServiceService } from './../../services/attending-service/attending-service.service';
import { Category } from 'src/app/models/category';
import { AttendingService } from './../../models/attending-service';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { LikesService } from './../../services/likes/likes.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { CaseReportService } from './../../services/case-report/case-report.service';
import { ModalController, NavController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { CaseReport } from 'src/app/models/case-report';
import { Like } from 'src/app/models/like';
import * as moment from 'moment';
import { Activity } from 'src/app/models/activity';
import { MyCasesEditPage } from '../my-cases-edit/my-cases-edit.page';

@Component({
  selector: 'app-peer-cases-article',
  templateUrl: './peer-cases-article.page.html',
  styleUrls: ['./peer-cases-article.page.scss'],
})
export class PeerCasesArticlePage implements OnInit {
  env = environment;
  @Input() initCaseReport: CaseReport;
  @Input() peerCasesTab: string;
  @Input() urlPath: string;
  caseReport: CaseReport;
  sharedTitleText = null;
  sharedDescText = null;
  sharedDate = null;
  isLoaded = false;
  isFavorited = false;
  isLiked = false;
  likeCount = 0;
  authUser: User;
  heroImageUrl = '';
  attendingService: AttendingService;
  parentCategory: Category;
  childCategory: Category;

  constructor(
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private caseReportService: CaseReportService,
    private likesService: LikesService,
    private notifications: NotificationsService,
    private authService: AuthService,
    private attendingSvcService: AttendingServiceService,
    private categoryService: CategoryService,
    private activityService: ActivityService,
    private analytics: BhAnalyticsService,
    private helpers: HelperUtilitiesService
  ) { }

  ngOnInit() {
    // this.loadCase()
  }

  ionViewWillEnter() {
    this.analytics.screenView('peer-cases-article', this.initCaseReport.repSeq);
    this.setArticleUrl(this.initCaseReport.repSeq);
    this.authUser = this.authService.getAuthUser();
    this.loadCase();
  }

  async loadCase() {
    try {
      if (this.initCaseReport) {
        const res =
          await this.caseReportService.getReportBySeq(this.initCaseReport.repSeq, true, true, false, this.authUser.userId).toPromise();
        if (res.reports && res.reports.length > 0) {
          this.caseReport = res.reports[0];
          this.addView();
          this.sharedTitleText = this.caseReport.sharedTitle ? this.caseReport.sharedTitle : 'Untitled';
          this.sharedDescText = this.caseReport.sharedDesc.replace(/(\r\n|\n|\r)/gm, '<br>');
          const sharedDateMoment = moment(this.caseReport.sharedDate, 'M/D/YYYY HH:mm');
          this.sharedDate =  sharedDateMoment.isValid() ? sharedDateMoment.format('MMMM D, YYYY') : this.sharedDate;
          this.isFavorited = this.caseReport.reportUserFavorites > 0;
          this.isLiked = this.caseReport.reportLikes > 0;
          this.likeCount = this.caseReport.reportLikes;
          this.heroImageUrl = this.caseReport.heroImage ? this.env.filesUrl + '/' + this.caseReport.heroImage.trim() : '/assets/generic-article-bg.png';
          this.loadCaseProperties();
          const act: Activity = {
            userId: this.authUser.userId,
            eventType: 'peer-article-view',
            eventDesc: 'Peer Article View: ' + this.caseReport.sharedTitle,
            pageName: 'peer-cases-article',
            repSeq: this.caseReport.repSeq,
            repRevSeq: this.caseReport.revSeq,
            patAccount: null,
            patMrn: null,
            source: 'M',
            active: 1
          };
          const actRes = await this.activityService.create(act).toPromise();
          this.isLoaded = true;
        } else {
          this.notifications.handleError('Unable to load Peer Case. (REF-NO-REP-SEQ)', 'peer-case-article: loadCase');
          this.dismiss();
        }
      } else {
        this.notifications.handleError('Unable to load Peer Case. (REF-NO-REP-PAR)', 'peer-case-article: loadCase');
        this.dismiss();
      }
    } catch (err) {
      this.notifications.handleError(err, 'peer-case-article: loadCase');
      this.dismiss();
    }
  }

  async loadCaseProperties() {
    try {
      const res = await this.attendingSvcService.getBySeq(this.caseReport.attSvcSeq).toPromise();
      this.attendingService = res;
    } catch (err) {
      this.notifications.handleError(err, 'peer-cases-article: loadCaseProperties: AttendingServices');
    }
  }

  async addView() {
    const view: CaseReportView = {
      repSeq: this.caseReport.repSeq,
      active: 1,
      name: null,
      description: null,
      addBy: this.authUser.userId,
      addDate: moment().format('M/D/YYYY HH:mm'),
      updateBy: this.authUser.userId,
      updateDate: moment().format('M/D/YYYY HH:mm')
    };
    this.caseReportService.addView(view).toPromise();
  }

  setArticleUrl(repSeq) {
    const tab = this.peerCasesTab ? this.peerCasesTab : 'newest';
    this.helpers.setAddressBarUrl('/tabs/peer-cases/' + tab + '/case/' + repSeq);
  }

  async editCase() {
    this.analytics.clickEvent('peer-cases-article: manage-case', this.initCaseReport.repSeq);
    const modal = await this.modalCtrl.create({
      component: MyCasesEditPage,
      cssClass: 'wide-modal floating super-wide',
      backdropDismiss: false,
      componentProps: {
        repSeq: this.caseReport.repSeq,
        isAdmin: true,
        urlPath: '/tabs/peer-cases/' + this.peerCasesTab + '/case/' + this.initCaseReport.repSeq,
        myCasesTab: null
      }
    });

    modal.onDidDismiss().then(data => {
      if (data.data.reload) {
        this.loadCase();
      }
    });

    return (await modal).present();

  }

  async likeCase(isLiking) {
    this.analytics.clickEvent('peer-cases-article: '  + (isLiking ? 'like' : 'unlike') + '-case', this.initCaseReport.repSeq);

    try {
      if (isLiking) {
        this.isLiked = true;
        this.likeCount += 1;
        const like: Like = {
          rcmSeq: 0,
          repSeq: this.caseReport.repSeq,
          likeType: 'like',
          active: 1,
          addBy: this.authUser.userId,
          addDate: moment().format('M/D/YYYY hh:mm a')
        };
        const res = await this.likesService.addLike(like).toPromise();
      } else {
        this.isLiked = false;
        this.likeCount -= 1;
        // Clear user like
        const res = await this.likesService.removeLike('like', this.caseReport.repSeq, 0, this.authUser.userId).toPromise();
      }
    } catch (err) {
      this.notifications.handleError(err, 'peer-case-article: like');
    }
  }

  async setFavorite(isFavoriting) {
    this.analytics.clickEvent('peer-cases-article: '  + (isFavoriting ? 'favorite' : 'unfavorite') + '-case', this.initCaseReport.repSeq);
    try {
      if (isFavoriting) {
        this.isFavorited = true;
        const like: Like = {
          rcmSeq: 0,
          repSeq: this.caseReport.repSeq,
          active: 1,
          likeType: 'favorite',
          addBy: this.authUser.userId,
          addDate: moment().format('M/D/YYYY hh:mm a')
        };
        const res = await this.likesService.addLike(like).toPromise();
      } else {
        this.isFavorited = false;
        // Clear user favorite
        const res = await this.likesService.removeLike('favorite', this.caseReport.repSeq, 0, this.authUser.userId).toPromise();
      }
    } catch (err) {
      this.notifications.handleError(err, 'peer-case-article: favorite');
    }
  }

  goToComments() {
    this.analytics.clickEvent('peer-cases-article: go-to-comments', this.initCaseReport.repSeq);
    setTimeout(() => {
      const chatColumn = document.getElementById('articleContentContainer');
      const end = document.getElementById('articleComments');
      const topPos = end.offsetTop;
      // chatColumn.scrollTop = topPos;
      chatColumn.scroll({
        top: topPos,
        behavior: 'smooth'
      });
    }, 250);

  }

  dismiss(dontOverwriteUrl = false) {
    this.analytics.clickEvent('peer-cases-article: dismiss', this.initCaseReport.repSeq);
    if (!dontOverwriteUrl) {
      this.helpers.setAddressBarUrl(this.urlPath);
    }
    this.modalCtrl.dismiss();
  }

  viewTag(ev) {
    this.analytics.clickEvent('peer-cases-article: view-tag', this.initCaseReport.repSeq + '/' + ev);
    this.dismiss(true);
    this.navCtrl.navigateRoot('/tabs/peer-cases/search?s=' + ev);
  }
}
