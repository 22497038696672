import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { Component, OnInit } from '@angular/core';
import { PopoverController, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CaseReportComment } from 'src/app/models/comment';
import { User } from 'src/app/models/user';
import { CommentsService } from 'src/app/services/comments/comments.service';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { MyCasesEditPage } from '../my-cases-edit/my-cases-edit.page';
import { CaseReport } from 'src/app/models/case-report';
import { PeerCasesArticlePage } from '../peer-cases-article/peer-cases-article.page';

@Component({
  selector: 'app-notifications-popover',
  templateUrl: './notifications-popover.page.html',
  styleUrls: ['./notifications-popover.page.scss'],
})
export class NotificationsPopoverPage implements OnInit {
  authUser: User;
  subscriptions: Subscription[] = [];
  adminFeedbackComments: CaseReportComment[] = [];
  peerCommentFavReports: CaseReport[] = [];

  constructor(
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    public authService: AuthService,
    private commentsService: CommentsService,
    private analytics: BhAnalyticsService
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.analytics.screenView('notifications-popover');
    this.subscribeToFeeds();
  }
  subscribeToFeeds() {
    // Subscribe to user
    this.subscriptions.push(
      this.authService.authUser.subscribe(val => this.authUser = val)
    );

    // Subscribe to unread admin comments
    this.subscriptions.push(
      this.commentsService.unreadAdminCommentsSubject.subscribe(com => {
        this.adminFeedbackComments = com.filter(c => c.commentType === 'admin-feedback' && c.likes === 0);
      })
    );

    // Subscribe to unread peer comments reports
    this.subscriptions.push(
      this.commentsService.unreadPeerCommentFavReports.subscribe(rep => {
        this.peerCommentFavReports = rep;
      })
    );

    this.commentsService.checkForUnreadAdminComments();
    this.commentsService.checkForUnreadPeerCommentFavReports();
  }

  async viewComment(repSeq) {
    this.analytics.clickEvent('notifications-popover: view-comment', '');
    this.popoverCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: MyCasesEditPage,
      cssClass: 'wide-modal super-wide',
      backdropDismiss: false,
      componentProps: {
        repSeq,
        isAdmin: false
      }
    });

    modal.onDidDismiss().then(res => {
      this.commentsService.checkForUnreadAdminComments();
    });
    return (await modal).present();
  }

  async viewArticle(caseReport: CaseReport) {
    this.analytics.clickEvent('home: view-article', '');
    const modal = await this.modalCtrl.create({
      component: PeerCasesArticlePage,
      componentProps: {
        initCaseReport: caseReport,
        urlPath: '/tabs/home',
        peerCasesTab: 'newest'
      },
      cssClass: 'wide-modal',
    });

    modal.onDidDismiss().then(res => {
      this.commentsService.checkForUnreadPeerCommentFavReports();
    });

    await modal.present();
  }

}
