import { Category } from 'src/app/models/category';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  env = environment;

  constructor(
    private http: HttpClient
  ) { }

  getAll(parentSeq: number = 0, activeOnly: boolean, catSeq: number = 0): Observable<any> {
    const activeOnlyParam = activeOnly ? 1 : 0;
    const url = `${this.env.apiUrl}/categories?activeOnly=${activeOnlyParam}&parentSeq=${parentSeq}&catSeq=${catSeq}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getBySeq(catSeq: number): Observable<any> {
    const url = `${this.env.apiUrl}/categories/${catSeq}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  filterFromArray(catArray: Category[], catSeq: number): Category {
    if (catArray && catSeq) {
      const match =  catArray.filter(a => a.catSeq.toString() === catSeq.toString())[0];
      return match ? match : null;
    } else {
      return null;
    }
  }

  create(category: Category): Observable<any> {
    const url = `${this.env.apiUrl}/categories`;
    return this.http.post(url, category).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  update(category: Category): Observable<Category> {
    const url = `${this.env.apiUrl}/categories/${category.catSeq}`;
    return this.http.put(url, category).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getReportCategoryAll(parentSeq: number, activeOnly: boolean, catSeq: number = 0): Observable<any> {
    const activeOnlyParam = activeOnly ? 1 : 0;
    const parentSeqParam = parentSeq ? `&parentSeq=${parentSeq}` : '';
    const catSeqParam = catSeq ? `&catSeq=${catSeq}` : '';
    const url = `${this.env.apiUrl}/reports/categories?activeOnly=${activeOnlyParam}${parentSeqParam}${catSeqParam}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getReportCategoryBySeq(catSeq: number): Observable<any> {
    const url = `${this.env.apiUrl}/reports/categories/${catSeq}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  addReportCategory(category: Category): Observable<any> {
    const url = `${this.env.apiUrl}/reports/categories`;
    return this.http.post(url, category).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  updateReportCategory(category: Category): Observable<any> {
    const url = `${this.env.apiUrl}/reports/categories/${category.catSeq}`;
    return this.http.put(url, category).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

}

