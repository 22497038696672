import { environment } from './../../../environments/environment';
import { AppValues } from './../../models/app-values';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppValuesService {
  env = environment;
  appValues: AppValues;

  constructor(
    private http: HttpClient,
  ) { }

  // Check if app values are loaded
  appValuesLoaded(): boolean {
    return (this.appValues.polStmnt && this.appValues.polRenewDays > 0);
  }

  getConfig(): Observable<AppValues> {
    const url = `${this.env.apiUrl}/settings`;
    return this.http.get(url).pipe(
      map((data: AppValues) => {
        return data;
      })
    );
  }

  update(appValues: AppValues) {
    if (appValues) {
      const url = `${this.env.apiUrl}/updateSettings`;
      return this.http.post(url, appValues).pipe(
        map((data: any) => {
          return data;
        })
      );
    }
  }

}
