import { CaseReportService } from './../case-report/case-report.service';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import { CaseReportComment } from 'src/app/models/comment';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { CaseReport } from 'src/app/models/case-report';
@Injectable({
  providedIn: 'root'
})
export class CommentsService {
  env = environment;
  unreadAdminCommentsSubject: BehaviorSubject<CaseReportComment[]> = new BehaviorSubject([]);
  unreadPeerCommentFavReports: BehaviorSubject<CaseReport[]> = new BehaviorSubject([]);
  refreshInterval = null;
  authUser: User;
  newAdminCommentTimer = null;
  unreadPeerCommentTimer = null;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private caseReportService: CaseReportService
  ) {
  }

  /**
   * Gets unread admin comments array
   */
  getUnreadAdminComments(): CaseReportComment[] {
    return this.unreadAdminCommentsSubject.getValue();
  }

  /**
   * Updates array: unread admin comments
   * @param comments Comments array to replace existing value
   */
  setUnreadAdminComments(comments: CaseReportComment[]) {
    this.unreadAdminCommentsSubject.next(comments);
  }

  /**
   * Gets favorite reports with unread comments array
   */
  getUnreadPeerCommentFavReports(): CaseReport[] {
    return this.unreadPeerCommentFavReports.getValue();
  }

  /**
   * Updates array: favorite reports with unread comments
   * @param reports Fav Reports with unread peer comments
   */
  setUnreadPeerCommentFavReports(reports: CaseReport[]) {
    this.unreadPeerCommentFavReports.next(reports);
  }

  startRefreshInterval() {
    if (!this.refreshInterval) {
      this.authUser = this.authService.getAuthUser();
      // Initial load
      if (this.authUser) {
        this.checkForUnreadAdminComments();
      }
      // Set refresh interval
      this.refreshInterval = setInterval(() => {
        this.checkForUnreadAdminComments();
        this.checkForUnreadPeerCommentFavReports();
      }, 120000);
    }
  }

  async checkForUnreadAdminComments() {
    if (this.newAdminCommentTimer) {
      clearTimeout(this.newAdminCommentTimer);
      this.newAdminCommentTimer = null;
    }
    this.newAdminCommentTimer = setTimeout(async () => {
      try {
        this.authUser = this.authService.getAuthUser();
        if (this.authUser && this.authUser.userId) {
          const res = await this.getUnreadAdminCommentItems().toPromise();
          this.setUnreadAdminComments(res.comments);
        }
      } catch (err) {
        console.log('Unable to check for unread comments:', err);
      }
    }, 250);
  }

  async checkForUnreadPeerCommentFavReports() {
    if (this.unreadPeerCommentTimer) {
      clearTimeout(this.unreadPeerCommentTimer);
      this.unreadPeerCommentTimer = null;
    }
    this.unreadPeerCommentTimer = setTimeout(async () => {

      try {
        this.authUser = this.authService.getAuthUser();
        if (this.authUser && this.authUser.userId) {
          const favoritedReports = await this.getUnreadPeerCommentFavReportsItems();
          this.setUnreadPeerCommentFavReports(favoritedReports);
        }
      } catch (err) {
        console.log('Unable to check for unread peer comments reports:', err);
      }
    }, 250);
  }


  getByReportSeq(repSeq: number, userId: string, activeOnly: boolean = true): Observable<any> {
    const activeOnlyParam = activeOnly ? 1 : 0;
    const url = `${this.env.apiUrl}/reports/comments?repSeq=${repSeq}&parentRcmSeq=0&userId=${userId}&activeOnly=${activeOnlyParam}`;

    return this.http.get(url).pipe(
      map((data: any) => {
        // Add Elapsed Time timestamps
        if (data && data.comments) {
          for (const comm of data.comments) {
            comm.elapsedTime = this.getElapsedTime(comm.addDate);
          }
        }
        console.log('Returning data', data);
        return data;
      })
    );
  }

  getByParentCommentSeq(parentRcmSeq: number, activeOnly: boolean = true): Observable<any> {
    const activeOnlyParam = activeOnly ? 1 : 0;
    const url = `${this.env.apiUrl}/reports/comments?parentRcmSeq=${parentRcmSeq}&activeOnly=${activeOnlyParam}`;

    return this.http.get(url).pipe(
      map((data: any) => {
        // Add Elapsed Time timestamps
        if (data && data.comments) {
          for (const comm of data.comments) {
            comm.elapsedTime = this.getElapsedTime(comm.addDate);
          }
        }
        return data;
      })
    );
  }

  getBySeq(rcmSeq: number): Observable<any> {
    const url = `${this.env.apiUrl}/reports/comments?rcmSeq=${rcmSeq}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        if (data && data.comments) {
          for (const comm of data.comments) {
            comm.elapsedTime = this.getElapsedTime(comm.addDate);
          }
        }
        return data;
      })
    );
  }

  getUnreadAdminCommentItems(): Observable<any> {
    const url = `${this.env.apiUrl}/reports/comments/unread?activeOnly=1`;
    return this.http.get(url).pipe(
      map((data: any) => {
        if (data && data.comments) {
          for (const comm of data.comments) {
            comm.elapsedTime = this.getElapsedTime(comm.addDate);
          }
        }
        return data;
      })
    );
  }

  async getUnreadPeerCommentFavReportsItems(): Promise<CaseReport[]> {
    try {
      const res =
        await this.caseReportService.getReportAll(
          true, true, true, false, false, true, null, 'sharedDate', this.authUser.userId, null).toPromise();
      const favoritedCases = (res.reports) ? res.reports.filter(r => r.newPeerComments > 0) : [];
      favoritedCases.forEach(c => {
        if (!c.heroImage) {
          c.heroImage = 'local:generic-article-bg.png'
        }
      });
      return favoritedCases;
    } catch (err) {
      console.log('Unable to load unread Peer Comment Fav Reports items', err);
      return [];
    }
  }


  addComment(comment: CaseReportComment): Observable<any> {
    const url = `${this.env.apiUrl}/reports/comments`;

    return this.http.post(url, comment).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  updateComment(comment: CaseReportComment): Observable<any> {
    const url = `${this.env.apiUrl}/reports/comments/${comment.rcmSeq}`;

    return this.http.put(url, comment).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getElapsedTime(dateTime: string): string {
    const dateTimeMoment = moment(dateTime, 'MM/DD/YYYY hh:mm a');
    let elapsedTime = '';
    if (dateTimeMoment.isValid()) {
      const nowMoment = moment();
      const minutesElapsed = nowMoment.diff(dateTimeMoment, 'minute')
      elapsedTime = this.convertMinutesToUserFriendlyTime(minutesElapsed);
    }
    return elapsedTime;
  }

  convertMinutesToUserFriendlyTime(minutes: number) {
    let pluralS = '';
    if (minutes === 0) {
      return 'Just now';
    } else if (minutes < 60) {
      pluralS = minutes > 1 ? 's' : '';
      return minutes + ' minute' + pluralS + ' ago';
    } else {
      const hours = Math.ceil(minutes / 60);
      if (hours < 24) {
        pluralS = hours > 1 ? 's' : '';
        return hours + ' hour' + pluralS + ' ago';
      } else {
        const days = Math.ceil(hours / 24);
        pluralS = days > 1 ? 's' : '';
        if (days < 30) {
          if (days > 7) {
            const weeks = Math.ceil(days / 7);
            pluralS = weeks > 1 ? 's' : '';
            return weeks + ' week' + pluralS + ' ago';
          } else {
            return days + ' day' + pluralS + ' ago';
          }
        } else {
          const months = Math.ceil(days / 30);
          pluralS = months > 1 ? 's' : '';
          if (months > 12) {
            const years = Math.ceil(months / 12);
            pluralS = years > 1 ? 's' : '';
            return years + ' year' + pluralS + ' ago';
          } else {
            return months + ' month' + pluralS + ' ago';
          }
        }
      }
    }

  }

}
