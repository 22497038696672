import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
/**
 * ID: bh-content-list-item
 * Name: BH List Item
 * Description: A data item used for strictly in lists
 * Version: 2
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-06-23 - MW - v1: Initial dev
 * 2021-06-28 - MW - v2: Fixed bottom-border color
 */

@Component({
  selector: 'bh-content-list-item',
  templateUrl: './bh-content-list-item.component.html',
  styleUrls: ['./bh-content-list-item.component.scss'],
})
export class BhContentListItemComponent implements OnInit {
  @Input() titleText = '';
  @Input() detailText1 = '';
  @Input() detailText2 = '';
  @Input() isMarked = true;
  @Input() value: any;
  @Output() selectItemEvent = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  selectItem() {
    this.selectItemEvent.emit(this.value);
  }

}
