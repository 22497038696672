import { environment } from './../../../environments/environment';
import { AttendingService } from './../../models/attending-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AttendingServiceService {
  env = environment;

  constructor(
    private http: HttpClient
  ) { }

  getAll(activeOnly: boolean, attSeq: number = 0): Observable<any> {
    const activeOnlyParam = activeOnly ? 1 : 0;
    const url = `${this.env.apiUrl}/attending-service?activeOnly=${activeOnlyParam}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getBySeq(attSeq): Observable<AttendingService> {
    const url = `${this.env.apiUrl}/attending-service/${attSeq}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  filterFromArray(attSvcArray: AttendingService[], attSvcSeq: number): AttendingService {
    if (attSvcArray && attSvcSeq) {
      const match =  attSvcArray.filter(a => a.attSeq.toString() === attSvcSeq.toString())[0];
      return match ? match : null;
    } else {
      return null;
    }
  }

  create(attendingService: AttendingService): Observable<any> {
    const url = `${this.env.apiUrl}/attending-service`;
    return this.http.post(url, attendingService).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  update(attendingService: AttendingService): Observable<any> {
    const url = `${this.env.apiUrl}/attending-service`;
    return this.http.put(url, attendingService).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

}
