import { Activity } from './../../models/activity';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  env = environment;

  constructor(
    private http: HttpClient
  ) { }

  getAll(fromDate: string, thruDate: string, dayThreshold: number, userId: string = null): Observable<any> {
    const userParam = userId ? `&userId=${userId}` : '';
    const url = `${this.env.apiUrl}/activity?fromDate=${fromDate}&thruDate=${thruDate}&dayThreshold=${dayThreshold}${userParam}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getBySeq(actSeq: number): Observable<any> {
    const url = `${this.env.apiUrl}/activity/${actSeq}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }


  create(activity: Activity): Observable<any> {
    const url = `${this.env.apiUrl}/activity`;
    return this.http.post(url, activity).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

}
