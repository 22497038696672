import { MyCasesPage } from './pages/my-cases/my-cases.page';
import { Subscription } from 'rxjs';
import { VerlockerService } from 'src/app/services/_core/verlocker/verlocker.service';
import { BhAnalyticsService } from './services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { AppValuesService } from './services/app-values/app-values.service';
import { Component } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Keyboard } from '@capacitor/keyboard';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  updateInterval = null;
  displayingVerlocker = false;
  loadingSub: Subscription = null;
  deeplinkSub: Subscription = null;
  isLoading = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private appValues: AppValuesService,
    private notifications: NotificationsService,
    private analytics: BhAnalyticsService,
    private verlockerService: VerlockerService,
    private deeplinks: Deeplinks,
    private navCtrl: NavController
  ) {
    this.initializeApp();
    this.subscribeToLoader();
    this.checkVersion();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      Keyboard.setAccessoryBarVisible({ isVisible: true});
      this.loadAppValues();
      this.analytics.initAnalytics();
      this.subscribeToDeeplinks();
    });
  }

  subscribeToLoader() {
    this.loadingSub = this.notifications.isLoadingBehaviorSubject.subscribe(val => {
      this.isLoading = val;
    });
  }

  subscribeToDeeplinks() {
    this.deeplinkSub = this.deeplinks.routeWithNavController(this.navCtrl, {
      // 'tabs/my-cases/:segment/case/:repSeq': MyCasesPage,
    }).subscribe(match => {
        // match.$route - the route we matched, which is the matched entry from the arguments to route()
        // match.$args - the args passed in the link
        // match.$link - the full link data
        console.log('Successfully matched route', match);
      }, nomatch => {
        // nomatch.$link - the full link data
        console.error('Got a deeplink that didn\'t match', nomatch);
      });
  }

  async loadAppValues() {
    try {
      const res = await this.appValues.getConfig().toPromise();
      this.appValues.appValues = res;
    } catch (err) {
      console.log('An error occurred', err);
      this.notifications.handleError(err, 'app-component: load-app-values');
    }
  }

  checkVersion() {
    if (!this.updateInterval) {
      this.verlockerService.checkVersion().toPromise();
      this.updateInterval = setInterval(() => {
        if (!this.verlockerService.displayingVerlocker) {
          this.verlockerService.checkVersion().toPromise();
        } else {
        }
      }, 120000);
    }
  }

}
