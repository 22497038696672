import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { NotificationsPopoverPageModule } from './pages/notifications-popover/notifications-popover.module';
import { FormsModule, FormBuilder } from '@angular/forms';
import { HomePageModule } from './pages/home/home.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginPageModule } from './pages/login/login.module';
import { TabsPageModule } from './pages/tabs/tabs.module';
import { VerlockerPageModule } from './pages/_core/verlocker/verlocker.module';
import { Device } from '@ionic-native/device/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Network } from '@ionic-native/network/ngx';
import { PinCheck } from '@ionic-native/pin-check/ngx';
import { BhAnalyticsRouteGuardService } from './services/_core/bh-analytics-route-guard/bh-analytics-route-guard.service';
import { InterceptorService } from './services/_core/interceptor/interceptor.service';
import { LoginRouteGuardService } from './services/_core/login-route-guard/login-route-guard.service';
import { NgxMaskIonicModule } from 'ngx-mask-ionic';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md',
    }),
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    VerlockerPageModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatDialogModule,
    MatMomentDateModule,
    NgxMaskIonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name: '__mydb',
      driverOrder: ['indexeddb', 'sqlite', 'websql']
    }),
    BrowserAnimationsModule,
    NotificationsPopoverPageModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    BarcodeScanner,
    Device,
    InAppBrowser,
    Keyboard,
    Network,
    PinCheck,
    FormBuilder,
    Deeplinks,
    LoginRouteGuardService,
    BhAnalyticsRouteGuardService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
