import { CaseReportView } from './../../models/case-report-view';
import { CaseReport } from './../../models/case-report';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { CaseReportComment } from 'src/app/models/comment';

@Injectable({
  providedIn: 'root'
})
export class CaseReportService {
  env = environment;

  constructor(
    private http: HttpClient
  ) { }

  getReportBySeq(
    repSeq: number,
    includeCounts: boolean,
    sharingInd: boolean,
    usersReportsOnly: boolean,
    userId: string
  ): Observable<any> {
    const includeCountsParam = includeCounts ? 1 : 0;
    const sharingParam = sharingInd ? 1 : 0;
    const usersReportsOnlyParam = usersReportsOnly ? 1 : 0;
    const url = `${this.env.apiUrl}/reports/${repSeq}?includeCounts=${includeCountsParam}` +
      `&sharingInd=${sharingParam}&public=${sharingParam}&usersRptsOnly=${usersReportsOnlyParam}&userId=${userId}`;

    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getReportAll(
    activeOnly: boolean,
    includeCounts: boolean,
    sharingInd: boolean,
    featured: boolean,
    usersReportsOnly: boolean,
    favorites: boolean,
    searchTerm: string,
    orderBy: 'liked' | 'views' | 'sharedDate',
    userId: string,
    page: number
  ): Observable<any> {
    const activeOnlyParam = activeOnly ? 1 : 0;
    const includeCountsParam = includeCounts ? 1 : 0;
    const sharingParam = sharingInd ? 1 : 0;
    const featuredParam = featured ? 1 : 0;
    const usersReportsOnlyParam = usersReportsOnly ? 1 : 0;
    const favoritesParam = favorites ? 1 : 0;
    const searchParam = searchTerm ? `&searchField=${searchTerm}` : '';
    const pageParam = page !== null || page === 0 ? `&page=${page}` : '';
    const url = `${this.env.apiUrl}/reports?activeOnly=${activeOnlyParam}&includeCounts=${includeCountsParam}${searchParam}` +
      `&sharingInd=${sharingParam}&public=${sharingParam}&featured=${featuredParam}&usersRptsOnly=${usersReportsOnlyParam}&favorites=${favoritesParam}${pageParam}` +
      `&sort=${orderBy}&userId=${userId}`;

    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getReportRevisionByReportSeq(repSeq: number, activeOnly: boolean, orderBy: string): Observable<any> {
    const activeOnlyParam = activeOnly ? 1 : 0;
    const url = `${this.env.apiUrl}/revisions?repSeq=${repSeq}&activeOnly=${activeOnlyParam}&orderBy=${orderBy}`;

    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getReportRevisionsByUserId(userId: string, draftsOnly: number = 0): Observable<any> {
    const url = `${this.env.apiUrl}/reports/revisions?userId=${userId}&draftsOnly=${draftsOnly}`;
    // const body = {
    //   userId,
    //   draftsOnly
    // };

    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getReportRevisionsByFilters(
    searchType: string,
    searchText: string,
    dateType: string,
    fromDate: string,
    thruDate: string,
    revStatus: string,
    attendingSvcId: number,
    parentCatId: number,
    childCatId: number,
    userId: string
  ): Observable<any> {
    // Check for date values
    // TODO: Switch to moment()
    if (fromDate && fromDate.length > 10) {
      fromDate = fromDate.substr(0, 10);
    }

    if (thruDate && thruDate.length > 10) {
      thruDate = thruDate.substr(0, 10);
    }

    const url = `${this.env.apiUrl}/reports/revisions/search?searchText=${searchText}&dateType=${dateType}&fromDate=${fromDate}` +
      `&thruDate=${thruDate}&revStatus=${revStatus}&attendingSvcId=${attendingSvcId}&parentCatId=${parentCatId}&childCatId=${childCatId}&userId=${userId}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getReportRevisionById(revSeq): Observable<CaseReport> {
    const url = `${this.env.apiUrl}/reports/revisions/${revSeq}`;

    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  createReport(caseReport: CaseReport): Observable<any> {
    const url = `${this.env.apiUrl}/reports`;

    return this.http.post(url, caseReport).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  createReportRevision(caseReport: CaseReport): Observable<any> {
    const url = `${this.env.apiUrl}/reports/revisions`;

    return this.http.post(url, caseReport).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  updateReport(caseReport: CaseReport): Observable<CaseReport> {
    const url = `${this.env.apiUrl}/reports/${caseReport.repSeq}`;

    return this.http.put(url, caseReport).pipe(
      map((data: any) => {
        return data;
      })
    );
  }


  addView(view: CaseReportView): Observable<any> {
    const url = `${this.env.apiUrl}/reports/views`;

    return this.http.post(url, view).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  updateReportRevision(caseReport: CaseReport): Observable<any> {
    const url = `${this.env.apiUrl}/reports/revisions/${caseReport.revSeq}`;
    // const headers = { 'content-type': 'multipart/form-data'};
    const headers = { 'BH-Dont-Assign-Headers': 'true' };
    const formData = new FormData();
    for (const key in caseReport) {
      if (Object.prototype.hasOwnProperty.call(caseReport, key)) {
        const value = caseReport[key];
        formData.append(key, value);
      }
    }
    return this.http.put(url, formData, { headers }).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  addStatusNamesForRevisions(caseReports: CaseReport[]): CaseReport[] {
    for (const rev of caseReports) {
      rev.revStatusName = this.getStatusName(rev.revStatus);
    }
    return caseReports;
  }

  getStatusName(revStatus: string): string {
    switch (revStatus) {
      case 'N':
        return 'New';

      case 'R':
        return 'Reviewed';

      case 'D':
        return 'Draft';

      case 'C':
        return 'Closed';

      default:
        return 'Unknown';
    }
  }


}
