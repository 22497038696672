import { HelperUtilitiesService } from 'src/app/services/_core/helper-utilities/helper-utilities.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { ActivityService } from './../../services/activity/activity.service';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
// import { CaseReportRevision } from './../../models/case-report';
import { CaseReportService } from './../../services/case-report/case-report.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { ModalController } from '@ionic/angular';
import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CaseReport } from 'src/app/models/case-report';
import { BhCommentsComponent } from 'src/app/components/bh-comments/bh-comments.component';
import { User } from 'src/app/models/user';
import { Activity } from 'src/app/models/activity';

@Component({
  selector: 'app-my-cases-edit',
  templateUrl: './my-cases-edit.page.html',
  styleUrls: ['./my-cases-edit.page.scss'],
})
export class MyCasesEditPage implements OnInit {
  @Input() repSeq = null;
  @Input() isAdmin = false;
  @Input() urlPath: string;
  @Input() myCasesTab: string;
  editMode = 'add';
  pageTitle = 'My Case';
  isMobile = false;
  caseReport: CaseReport;
  commentText = '';
  isAdminFeedback = true;
  authUser: User;
  isSaved = false;
  isInit = false;

  @ViewChild(BhCommentsComponent)
  private commentsComp!: BhCommentsComponent;

  constructor(
    private modalCtrl: ModalController,
    public formBuilder: FormBuilder,
    private notifications: NotificationsService,
    private caseReportService: CaseReportService,
    private authService: AuthService,
    private activityService: ActivityService,
    private analytics: BhAnalyticsService,
    private helpers: HelperUtilitiesService
  ) { }

  ngOnInit() {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const width = event.target.innerWidth;
    if (width > 767) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }

  ionViewWillEnter() {
    const authUser = this.authService.getAuthUser();
    if (!this.isAdmin) {
      this.isAdminFeedback = false;
    }
    this.analytics.screenView('my-cases-edit-' + (this.isAdmin ? 'admin' : 'user'), this.repSeq);
    this.setCaseUrl(this.repSeq);
    setTimeout(() => { this.isInit = true; }, 100);

  }

  ionViewDidEnter() {
    this.authUser = this.authService.getAuthUser();
    this.loadCase();
    this.scrollToEndOfChat();
    // setTimeout(() => { this.isInit = true; }, 250);
  }

  setCaseUrl(repSeq) {
    if (this.isAdmin) {
      this.helpers.setAddressBarUrl('/tabs/manage/cases/case/' + repSeq);
    } else {
      const tab = this.myCasesTab ? this.myCasesTab : 'open';
      this.helpers.setAddressBarUrl('/tabs/my-cases/' + tab + '/case/' + repSeq);
    }
  }

  async loadCase() {
    // Set Title
    this.pageTitle = this.isAdmin ? 'Manage Case' : 'My Case';
    // Load Report/Revision
    if (this.repSeq) {
      try {
        const repRes =
          await this.caseReportService.getReportBySeq(this.repSeq, false, false, !this.isAdmin, this.authUser.userId).toPromise();
        if (repRes.reports.length > 0) {
          this.caseReport = repRes.reports[0];
          const act: Activity = {
            userId: this.authUser.userId,
            eventType: 'report-view',
            eventDesc: 'Report View',
            pageName: 'my-cases-edit' + ((this.isAdmin) ? '-admin' : '-user'),
            repSeq: this.caseReport.repSeq,
            repRevSeq: this.caseReport.revSeq,
            patAccount: this.caseReport.patAccount,
            patMrn: this.caseReport.patMrn,
            source: 'M',
            active: 1
          };
          const actRes = await this.activityService.create(act).toPromise();
        } else {
          this.notifications.showError('Case did not load.');
          this.modalCtrl.dismiss();
        }
      } catch (err) {
        this.notifications.handleError(err, 'my-cases-edit: loadReport')
        this.modalCtrl.dismiss();
      }
    } else {
      this.notifications.showError('Unable to load: Missing Case Report ID.');
      this.modalCtrl.dismiss();
    }
  }

  dismiss(reload = false) {
    this.analytics.clickEvent('my-cases-edit-' + (this.isAdmin ? 'admin' : 'user') + ': dismiss', this.caseReport.repSeq);
    this.helpers.setAddressBarUrl(this.urlPath);
    this.modalCtrl.dismiss({
      reload
    });
  }

  scrollToEndOfChat() {
    setTimeout(() => {
      const chatColumn = document.getElementById('chatColumnDesktop');
      const end = document.getElementById('endOfChatDesktop');
      const topPos = end.offsetTop;
      // chatColumn.scrollTop = topPos;
      chatColumn.scroll({
        top: topPos,
        behavior: 'smooth'
      });
    }, 250);
  }

  async save(caseReport: CaseReport) {
    this.analytics.clickEvent('my-cases-edit-' + (this.isAdmin ? 'admin' : 'user') + ': save', caseReport.repSeq);
    // will save
    this.notifications.startLoading();
    try {
      // Update revision-- get new revision seq
      const res = await this.caseReportService.updateReportRevision(caseReport).toPromise();
      caseReport.currRevSeq = res.revSeq;
      caseReport.currRevisionSeq = res.revSeq;
      // Update current Revision Seq-- Dave is doing this on the updateReportRevision endpoint
      // const repRes = await this.caseReportService.updateReport(caseReport).toPromise();
      this.isSaved = true;
      this.loadCase();
      this.notifications.stopLoading();
      this.notifications.showAlert('Success', 'Your work was successfully saved.');
    } catch (err) {
      this.notifications.handleError(err, 'my-cases-edit: save');
    }
  }

  async send(ev = null) {
    this.analytics.clickEvent('my-cases-edit-' + (this.isAdmin ? 'admin' : 'user') + ': send-message', this.caseReport.repSeq);
    if (ev) {
      ev.preventDefault();
    }
    const text = this.commentText;
    this.commentText = '';
    this.commentsComp.addComment(text, this.isAdminFeedback);
  }

}
