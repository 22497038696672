import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Like } from 'src/app/models/like';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LikesService {
  env = environment;

  constructor(
    private http: HttpClient
  ) { }


  getBySeq(rclSeq: number): Observable<any> {
    const url = `${this.env.apiUrl}/reports/comments/likes?rclSeq=${rclSeq}`;

    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getByReportSeq(repSeq: number, activeOnly: boolean = true): Observable<any> {
    const activeOnlyParam = activeOnly ? 1 : 0;
    const url = `${this.env.apiUrl}/reports/comments/likes?repSeq=${repSeq}&activeOnly=${activeOnlyParam}`;

    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  addLike(like: Like): Observable<any> {
    const url = `${this.env.apiUrl}/reports/comments/likes`;

    return this.http.post(url, like).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  removeLike(type: string, repSeq: number, rcmSeq: number, userId: string): Observable<any> {
    const url = `${this.env.apiUrl}/reports/comments/likes?likeType=${type}&repSeq=${repSeq}&rcmSeq=${rcmSeq}&userId=${userId}`;

    return this.http.delete(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  updateLike(like: Like): Observable<any> {
    const url = `${this.env.apiUrl}/reports/comments/likes/${like.rclSeq}`;

    return this.http.put(url, like).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
}
