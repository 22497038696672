import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { HelperUtilitiesService } from 'src/app/services/_core/helper-utilities/helper-utilities.service';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { User } from 'src/app/models/user';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { CommentsService } from './../../services/comments/comments.service';
import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, HostListener, OnDestroy } from '@angular/core';
import { CaseReportComment } from 'src/app/models/comment';
import * as moment from 'moment';
@Component({
  selector: 'bh-comments',
  templateUrl: './bh-comments.component.html',
  styleUrls: ['./bh-comments.component.scss'],
})
export class BhCommentsComponent implements OnChanges, OnDestroy {
  @Input() repSeq: number;
  @Input() repAddBy: string;
  @Input() conversationType: 'peer' | 'admin' = 'peer';
  @Input() isAdmin = false;
  @Input() order: 'oldest' | 'newest' = 'oldest';
  @Output() scrollToEndEvent = new EventEmitter();
  @Output() commentsLoadedEvent = new EventEmitter<number>();
  commentText: string;
  comments: CaseReportComment[] = [];
  pendingAcknowledgements = 0;
  isLoaded = false;
  refreshInterval = null;
  userInitials = null;
  currentOrder = 'oldest';
  authUser: User;

  constructor(
    private commentsService: CommentsService,
    private authService: AuthService,
    private notifications: NotificationsService,
    private helpers: HelperUtilitiesService,
    private analytics: BhAnalyticsService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if ('repSeq' in changes && this.repSeq) {
      this.authUser = this.authService.getAuthUser();
      this.setUserBadge();
      this.loadComments();
      this.setRefreshInterval();
    }
  }

  ngOnDestroy() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    }
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    this.setRefreshInterval();
  }

  setRefreshInterval() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    }
    this.refreshInterval = setInterval(() => { this.loadComments(); }, 120000);
  }

  async loadComments() {
    const authUser = this.authService.getAuthUser();
    try {
      const res = await this.commentsService.getByReportSeq(this.repSeq, authUser.userId, true).toPromise();
      const newComments = (this.conversationType === 'admin') ?
        res.comments.filter(c => c.commentType === 'admin' || c.commentType === 'admin-feedback') :
        res.comments.filter(c => c.commentType === 'peer').reverse();
      if (this.comments !== newComments) {
        this.currentOrder = (this.conversationType === 'admin') ? 'oldest' : 'newest';
        this.comments = newComments;
        const adminFeedback = this.comments.filter(c =>
          c.commentType === 'admin-feedback' &&
          authUser.userId === this.repAddBy &&
          c.likes === 0 &&
          !this.isAdmin
        );
        this.pendingAcknowledgements = adminFeedback.length;
        // this.reorderComments();
        this.isLoaded = true;
        this.scrollToEndEvent.emit(true);
        this.commentsLoadedEvent.emit(this.comments.length);
      }
    } catch (err) {
      this.notifications.handleError(err, 'bh-comments: loadComments');
    }
  }

  // async reorderComments() {
  //   if (this.currentOrder !== this.order && this.conversationType === 'peer') {
  //     const revComments = this.comments.reverse();
  //     this.comments = revComments;
  //     this.currentOrder = this.order;
  //   }
  // }

  async post(ev) {
    this.analytics.clickEvent('bh-comments: send-message', '');
    if (ev) {
      ev.preventDefault();
    }
    this.addComment(this.commentText);
  }

  async addComment(commentText, isAdminFeedback = false) {
    try {
      if (commentText && commentText.trim()) {
        const authUser = this.authService.getAuthUser();
        const newComment: CaseReportComment = {
          parentRcmSeq: 0,
          repSeq: this.repSeq,
          commentType: this.conversationType === 'admin' ? (isAdminFeedback ? 'admin-feedback' : 'admin') : 'peer',
          edited: 0,
          text: commentText,
          active: 1,
          likes: 0,
          dislikes: 0,
          replies: 0,
          addBy: authUser.userId,
          addByName: authUser.fullName,
          addDate: moment().format('M/D/YYYY hh:mm a'),
          updateBy: authUser.userId,
          updateDate: moment().format('M/D/YYYY hh:mm a'),
          elapsedTime: this.commentsService.getElapsedTime(moment().format('M/D/YYYY hh:mm a'))
        };
        const res = await this.commentsService.addComment(newComment).toPromise();
        newComment.rcmSeq = res.rcmSeq;
        if (this.currentOrder === 'oldest') {
          this.comments.push(newComment);
        } else {
          this.comments.unshift(newComment);
        }
        this.commentText = '';
        this.scrollToEndEvent.emit(true);
      }
    } catch (err) {
      this.notifications.handleError(err, 'bh-comments: addComment');
    }
  }

  setUserBadge() {
    const authUser = this.authService.getAuthUser();
    if (authUser.fullName) {
      const firstName = this.helpers.getFirstName(authUser.fullName);
      const lastName = this.helpers.getLastName(authUser.fullName);
      const firstNameInitial = firstName ? firstName.substr(0, 1) : null;
      const lastNameInitial = lastName ? lastName.substr(0, 1) : null;
      this.userInitials = firstNameInitial + lastNameInitial;
    }
  }

}
